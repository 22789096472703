import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RutasPermitidasGuard } from '@guards/rutas-permitidas.guard';
import { MainComponent } from './main.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'full',
      },
      {
        path: 'inicio',
        loadChildren: () => import('@pages/inicio/inicio.module').then(m => m.InicioModule),
        canActivate: [RutasPermitidasGuard],
      },
      {
        path: 'areas-de-estacionamiento',
        loadChildren: () => import('@pages/areas-de-estacionamiento/areas-de-estacionamiento.module').then(m => m.AreasDeEstacionamientoModule),
        canActivate: [RutasPermitidasGuard],
      },
      {
        path: 'cambiar-password',
        loadChildren: () => import('@pages/cambiar-password/cambiar-password.module').then(m => m.CambiarPasswordModule),
        canActivate: [RutasPermitidasGuard],
      },
      {
        path: 'usuarios',
        loadChildren: () => import('@pages/usuarios/usuarios.module').then(m => m.UsuariosModule),
        canActivate: [RutasPermitidasGuard],
      },
      {
        path: 'inspectores',
        loadChildren: () => import('@pages/inspectores/inspectores.module').then(m => m.InspectoresModule),
        canActivate: [RutasPermitidasGuard],
      },
      {
        path: 'horarios',
        loadChildren: () => import('@pages/horarios/horarios.module').then(m => m.HorariosModule),
        canActivate: [RutasPermitidasGuard],
      },
      {
        path: 'configuracion',
        loadChildren: () => import('@pages/configuracion/configuracion.module').then(m => m.ConfiguracionModule),
        canActivate: [RutasPermitidasGuard],
      },
      {
        path: 'feriados',
        loadChildren: () => import('@pages/feriados/feriados.module').then(m => m.FeriadosModule),
        canActivate: [RutasPermitidasGuard],
      },
      {
        path: 'franquicias',
        loadChildren: () => import('@pages/franquicias/franquicias.module').then(m => m.FranquiciasModule),
        canActivate: [RutasPermitidasGuard],
      },
      {
        path: 'beneficiarios',
        loadChildren: () => import('@pages/beneficiarios/beneficiarios.module').then(m => m.BeneficiariosModule),
        canActivate: [RutasPermitidasGuard],
      },
      {
        path: 'tarifas',
        loadChildren: () => import('@pages/tarifas/tarifas.module').then(m => m.TarifasModule),
        canActivate: [RutasPermitidasGuard],
      },
      {
        path: 'comercios-adheridos',
        loadChildren: () => import('@pages/comercios-adheridos/comercios-adheridos.module').then(m => m.ComerciosAdheridosModule),
        canActivate: [RutasPermitidasGuard],
      },
      {
        path: 'seguimiento-colectivos',
        loadChildren: () => import('@pages/seguimiento-colectivos/seguimiento-colectivos.module').then(m => m.SeguimientoColectivosModule),
        canActivate: [RutasPermitidasGuard],
      },
      {
        path: 'estacionamientos',
        loadChildren: () => import('@pages/estacionamientos-en-curso/estacionamientos-en-curso.module').then(m => m.EstacionamientosEnCursoModule),
        canActivate: [RutasPermitidasGuard],
      },
      {
        path: 'liquidaciones',
        loadChildren: () => import('@pages/liquidaciones/liquidaciones.module').then(m => m.LiquidacionesModule),
        canActivate: [RutasPermitidasGuard],
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
