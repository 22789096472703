export const TITLE = "FunesAdmin";
export const THEME : any= {
  primary: "#006833",
  primaryDark: "#003d0a",
  primaryDarker: "#003308",
  primaryLight: "#168C42",
  primaryLighter: "#a3dcb8",
  secondary: "#9E9E9E",
  secondaryDark: "#212121",
  secondaryLight: "#E0E0E0",
  alert: "#b40000",
  alertDark: "#6d0000",
};
export const THEME_RGBA : any= {
  primary: "rgba(0, 104, 51, 0.5)",
  primaryDark: "rgba(0, 61, 10, 0.5)",
  primaryDarker: "rgba(0, 51, 8, 0.5)",
  primaryLight: "rgba(22, 140, 66, 0.5)",
  primaryLighter: "rgba(163, 220, 184, 0.5)",
};
