import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RutasPermitidasGuard implements CanActivate {

  constructor(
    private authService: AuthService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let permitirRuta: boolean = true;
    let rutasPermitidas! : string[];
    const rutasSiemprePermitidas = ['cambiar-password'];

    const permitirRuta$ = this.authService.me().pipe(map(()=>{

      rutasPermitidas = [ ...this.authService.usuario?.rutas!, ...rutasSiemprePermitidas ]
      for (const segmento of route.url) {
        permitirRuta = rutasPermitidas.includes(segmento.path)
        if(!permitirRuta) break;
      }
      return permitirRuta

    }));

    return permitirRuta$;
  }

}
