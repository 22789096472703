import { ISeccion } from '@/models/seccion.model';
import { UsuarioActual } from '@/models/usuarioActual.model';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { EstadoSidebarService } from '@services/estado-sidebar.service';
import { TITLE } from 'brand/const';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  title = TITLE;

  events: string[] = [];
  opened: boolean = true;

  secciones: ISeccion[] = [];

  usuario: UsuarioActual | null = null;

  constructor(
    private authService: AuthService,
    private estadoSidebarService: EstadoSidebarService,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.authService.me().subscribe(() => {
      this.usuario = this.authService.usuario
      this.habilitarSecciones();
      this.detectarSeccionActiva();
    });
  }

  ngAfterViewInit() {

  }

  doWhenOpen() {

  }

  doWhenClose() {

  }

  toggle() {
    this.estadoSidebarService.estadoSidebarEvento(this.opened);
    this.sidenav.toggle()
  }

  public logout() {
    this.authService.logout().subscribe();
  }

  private habilitarSecciones() {
    if (!this.usuario) return;

    this.usuario.rutas.forEach(rutaPermitida => {

      switch (rutaPermitida) {
        case 'inicio':
          this.secciones.push(
            {
              titulo: "Inicio",
              ruta: "inicio",
              icono: "home"
            },
          )
          break;
        case 'areas-de-estacionamiento':
          this.secciones.push(
            {
              titulo: "Áreas de estacionamiento",
              ruta: "areas-de-estacionamiento",
              icono: "map"
            },
          )
          break;
        case 'usuarios':
          this.secciones.push(
            {
              titulo: "Usuarios",
              ruta: "usuarios",
              icono: "manage_accounts"
            },
          )
          break;
        case 'inspectores':
          this.secciones.push(
            {
              titulo: "Inspectores",
              ruta: "inspectores",
              icono: "supervisor_account"
            },
          )
          break;
        case 'tarifas':
          this.secciones.push(
            {
              titulo: "Tarifas",
              ruta: "tarifas",
              icono: "payments",
            },
          )
          break;
        case 'horarios':
          this.secciones.push(
            {
              titulo: "Horarios",
              ruta: "horarios",
              icono: "schedule",
            },
          )
          break;
        case 'feriados':
          this.secciones.push(
            {
              titulo: "Feriados",
              ruta: "feriados",
              icono: "event"
            },
          )
          break;
        case 'comercios-adheridos':
          this.secciones.push(
            {
              titulo: "Comercios adheridos",
              ruta: "comercios-adheridos",
              icono: "store"
            },
          )
          break;
        case 'seguimiento-colectivos':
          this.secciones.push(
            {
              titulo: "Transportes públicos",
              ruta: "seguimiento-colectivos",
              icono: "directions_bus"
            },
          )
          break;
        // case 'estacionamientos':
        //   this.secciones.push(
        //     {
        //       titulo: "Estacionamientos en curso",
        //       ruta: "estacionamientos",
        //       icono: "car-lot-park",
        //       esSvg : true
        //     },
        //   )
        //   break;
        case 'liquidaciones':
          this.secciones.push(
            {
              titulo: "Liquidaciones",
              ruta: "liquidaciones",
              icono: "currency_exchange"
            }
          )
          break;
        case 'configuracion':
          this.secciones.push(
            {
              titulo: "Configuración",
              ruta: "configuracion",
              icono: "settings"
            }
          )
          break;

        default:
          this.asignarSubmenu(rutaPermitida);
          break;
      }

    });
  }

  private asignarSubmenu(rutaPermitida: string) {

    if (['franquicias', 'beneficiarios'].includes(rutaPermitida)) {

      let seccion = this.secciones.find((s) => s.titulo == 'Franquicias y beneficiarios')
      let index: number;

      if (!seccion) {
        index = this.secciones.push({
          titulo: "Franquicias y beneficiarios",
          icono: "handshake",
          activo: false,
          submenu: []
        },
        )
        index--;
        seccion = this.secciones[index];
      }
      switch (rutaPermitida) {
        case 'franquicias':
          seccion.submenu?.push(
            {
              titulo: "Franquicias",
              ruta: "franquicias",
              icono: "storefront"
            },
          )
          break;
        case 'beneficiarios':
          seccion.submenu?.push(
            {
              titulo: "Beneficiarios",
              ruta: "beneficiarios",
              icono: "person"
            }
          )
          break;

        default:
          break;
      }
    }
  }


  detectarSeccionActiva() {
    this.secciones.map((seccion) => {
      if (seccion.submenu) {
        let conicideRuta = seccion.submenu?.find((item) => `/${item.ruta}` == this.router.url);
        if (conicideRuta !== undefined) {
          seccion.activo = true;
        }
      }
      return seccion
    })
  }

}
