<div class="body">
  <div class="login">
    <img src="../../../brand/img/logo.png" alt="" width="160px">
    <h1 class="mt-4 color-primary"><span>{{ title }}</span></h1>
    <!-- <h2 class=""></h2> -->
    <form [formGroup]="loginForm">
      <mat-form-field class="ancho" appearance="fill">
        <mat-label>Usuario</mat-label>
        <input matInput formControlName="usuario" autofocus>
      </mat-form-field>
      <mat-form-field class="ancho" appearance="fill">
        <mat-label>Contraseña</mat-label>
        <input matInput formControlName="contrasena" type="password" (keyup.enter)="ingresar()">
      </mat-form-field>
    </form>
    <br>
    <button (click)="ingresar()" class="ancho" mat-flat-button color="primary">Ingresar</button>
  </div>
</div>
