import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CustomIconsService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }

  public registrarIconosCustom(){
    this.matIconRegistry.addSvgIcon(
      'car',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/car.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ticket',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/ticket.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'officer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/officer.svg')
    );
  }
}
