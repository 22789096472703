import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CustomIconsService } from '@services/custom-icons.service';
import { TITLE } from 'brand/const';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = TITLE;

  public constructor(
    private titleService: Title,
    private customIcons : CustomIconsService
    ) {
    titleService.setTitle(this.title);
    this.customIcons.registrarIconosCustom();
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
